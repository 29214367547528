.signup-container {
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: #f9f9f9;
  position: relative;
  text-align: center;
}

.signup-form h2 {
  text-align: center;
  color: #333;
}

.signup-form .form-group {
  margin-bottom: 15px;
}

.signup-form label {
  text-align: left;
  display: block;
  margin-bottom: 5px;
  color: #555;
}

.signup-form .name-inputs {
  display: flex;
  justify-content: space-between;
}

.signup-form .name-inputs input {
  width: 48%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.signup-form input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.signup-form button {
  width: 100%;
  padding: 10px;
  margin-top: 10px;
  border: none;
  border-radius: 4px;
  background-color: #007bff;
  color: white;
  font-size: 16px;
  cursor: pointer;
}

.signup-form button:hover {
  background-color: #0056b3;
}

.signup-form .login-link {
  margin-top: 15px;
  text-align: center;
  color: #007bff;
  cursor: pointer;
  text-decoration: underline;
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}
