.dashboard {
  max-width: 900px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: #f9f9f9;
  position: relative;
}

.dashboard h3 {
  text-align: center;
  color: #333;
}

.dashboard .logout-link {
  position: absolute;
  top: 10px;
  right: 20px;
  font-size: 0.8em;
  color: #007bff;
  cursor: pointer;
  text-decoration: underline;
}

.dashboard .settings-link {
  position: absolute;
  top: 30px; /* ログアウトリンクの下に配置 */
  right: 20px;
  font-size: 0.8em;
  color: #007bff;
  cursor: pointer;
  text-decoration: underline;
}

.dashboard .csv-link {
  position: absolute;
  top: 10px;
  left: 20px;
  font-size: 0.8em;
  color: #007bff;
  cursor: pointer;
  text-decoration: underline;
}

.dashboard .form-group {
  flex: 1;
  margin-right: 10px;
}

.dashboard label {
  margin-bottom: 5px;
  color: #555;
}

.dashboard .current-date,
.dashboard .current-time {
  text-align: center;
  font-size: 1.5em;
  margin-bottom: 15px;
}

.dashboard .current-time {
  font-size: 4em;
}

.dashboard .button-row {
  display: flex;
  justify-content: space-between;
  align-items: center; /* 高さを揃えるために追加 */
  width: 100%;
  gap: 10px; /* 各要素の間に均等なスペースを追加 */
}

.dashboard .button-row select,
.dashboard .button-row button {
  width: 24%;
  height: 42px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
}

.dashboard .button-row select {
  margin-top: 1%;
  border: 1px solid #ccc;
}

.dashboard .button-row button {
  border: none;
  color: white;
}

.dashboard .button-row .clock-in-button {
  background-color: #007bff;
}

.dashboard .button-row .clock-in-button:hover {
  background-color: #0056b3;
}

.dashboard .button-row .clock-out-button {
  background-color: #28a745;
}

.dashboard .button-row .clock-out-button:hover {
  background-color: #218838;
}

.dashboard .button-row .apply-button {
  background-color: #ffc107;
}

.dashboard .button-row .apply-button:hover {
  background-color: #e0a800;
}

.dashboard ul {
  list-style: none;
  padding: 0;
}

.dashboard li {
  padding: 10px;
  margin-bottom: 5px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.dashboard li.saturday {
  color: blue;
}

.dashboard li.sunday {
  color: red;
}

.dashboard li.holiday {
  color: red;
}

.dashboard li.weekday-no-record {
  background-color: #ffebcd; /* 薄オレンジ色 */
}

.dashboard li .record-details {
  display: flex;
  justify-content: space-between;
  width: 100%; /* 左側の5分の4のスペースから全幅に変更 */
}

.dashboard li .record-details div {
  flex: 1;
  text-align: center;
}

.edit-dialog {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  z-index: 1000;
  width: 300px;
}

.edit-dialog-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.edit-dialog h3 {
  margin: 0;
}

.close-button {
  cursor: pointer;
  font-size: 1.2em;
}

.edit-dialog .form-group {
  margin-bottom: 15px;
}

.edit-dialog .button-row {
  display: flex;
  justify-content: space-between;
}

.edit-dialog .button-row button {
  width: 48%;
  padding: 8px; /* 高さを低くしました */
  border: none;
  border-radius: 4px;
  background-color: #007bff;
  color: white;
  font-size: 14px; /* フォントサイズを少し小さくしました */
  cursor: pointer;
}

.edit-dialog .button-row button:hover {
  background-color: #0056b3;
}

.edit-dialog .button-row .delete-button {
  background-color: #dc3545;
}

.edit-dialog .button-row .delete-button:hover {
  background-color: #c82333;
}

.navigation {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  width: 100%; /* 横幅を広く設定 */
  max-width: 300px; /* 必要に応じて最大幅を設定 */
  margin: 0 auto; /* 中央に配置 */
}

.navigation button {
  background-color: #003366; /* 背景色を紺色に設定 */
  color: white; /* テキスト色を白に設定 */
  border: none;
  font-size: 1.2em; /* ボタンのフォントサイズを小さく */
  cursor: pointer;
  flex: 0 0 50px; /* ボタンの幅を固定 */
  border-radius: 4px; /* 角を丸くする */
  padding: 5px; /* 内側の余白を追加 */
  margin: 0 5px; /* 端に寄せるための外側の余白を追加 */
}

.navigation span {
  font-size: 1.2em; /* フォントサイズを小さく */
  font-weight: bold;
  flex: 1; /* 年月表示の幅を確保 */
  text-align: center;
}

.settings-button {
  position: absolute;
  top: 10px;
  right: 60px; /* ログアウトボタンの隣 */
  font-size: 1.5em;
  color: #007bff;
  cursor: pointer;
}

.settings-dialog {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  z-index: 1000;
  width: 300px;
}

.settings-dialog-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.settings-dialog h3 {
  margin: 0;
}

.settings-dialog .close-button {
  cursor: pointer;
  font-size: 1.2em;
}

.settings-dialog .form-group {
  margin-bottom: 15px;
}

.settings-dialog .button-row {
  display: flex;
  justify-content: space-between;
}

.settings-dialog .button-row button {
  width: 100%;
  padding: 8px;
  border: none;
  border-radius: 4px;
  background-color: #007bff;
  color: white;
  font-size: 14px;
  cursor: pointer;
}

.settings-dialog .button-row button:hover {
  background-color: #0056b3;
}

.loading-spinner {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 1.5em;
  color: #007bff;
  z-index: 1001;
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1001; /* ダイアログの上に表示するために高い値に設定 */
}

.loading-spinner .spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border-left-color: #09f;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.no-interaction {
  pointer-events: none;
  opacity: 0.5;
}

.no-interaction .edit-dialog,
.no-interaction .settings-dialog {
  pointer-events: all;
  opacity: 1;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 999; /* ダイアログの下に表示するために高い値に設定 */
}

.edit-dialog,
.settings-dialog {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  z-index: 1000; /* オーバーレイの上に表示するために高い値に設定 */
}

.form-group-check {
  display: inline-block;
  margin-bottom: 5px;
  color: #555;
  vertical-align: middle;
}

.form-group-id label {
  display: inline-block;
  width: 50%;
  margin-bottom: 5px;
  color: #555;
  vertical-align: middle;
}

.form-group-id input,
.form-group-id select {
  width: 50%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  vertical-align: middle;
}

.form-group-name {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px; /* フォーム間の余白 */
}

.form-group-name label {
  width: auto;
  margin-right: 5px;
}

.form-group-name input {
  width: calc(50% - 10px); /* 入力フォームを均等に並べる */
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  vertical-align: middle;
}
