.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f5f5f5;
}

.login-form {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 300px;
}

.login-form h2 {
  margin-bottom: 20px;
  text-align: center;
}

.login-form .form-group {
  margin-bottom: 15px;
}

.login-form label {
  display: block;
  margin-bottom: 5px;
  color: #555;
}

.login-form input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.login-form button {
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 4px;
  background-color: #007bff;
  color: white;
  font-size: 16px;
  cursor: pointer;
  margin-top: 10px;
}

.login-form button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.login-form button:hover:not(:disabled) {
  background-color: #0056b3;
}

.signup-link {
  margin-top: 10px;
  text-align: center;
  color: #007bff;
  cursor: pointer;
  text-decoration: underline;
}
.signup-link span:hover {
  color: #0056b3;
}

.loading-spinner {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 1.5em;
  color: #007bff;
  z-index: 1000;
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.7);
  z-index: 999;
}

.error-message {
  color: red;
  font-size: 0.9em;
  margin-top: 10px;
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1001; /* ダイアログの上に表示するために高い値に設定 */
}

.loading-spinner .spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border-left-color: #09f;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.link-group {
  display: flex;
  flex-direction: column; /* 縦に並べる */
  align-items: center; /* 中央に配置 */
  margin-top: 10px;
}

.signup-link,
.password-reset-link {
  color: #007bff;
  cursor: pointer;
  text-decoration: underline;
  margin-top: 5px; /* リンク間の余白を追加 */
}

.signup-link:hover,
.password-reset-link:hover {
  text-decoration: none;
}

.loading-submessage {
  font-size: 0.8em;
  color: #666;
  margin-top: 5px;
}

.email-verification {
  text-align: center;
  padding: 20px;
}

.email-verification p {
  margin-bottom: 20px;
}

.email-verification button {
  display: block;
  margin: 10px auto;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: 200px; /* ボタンの幅を一定に設定 */
  text-align: center; /* テキストを中央揃え */
}

.email-verification button:hover {
  background-color: #0056b3;
}

.error-dialog {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  z-index: 1000;
  width: 300px;
}

.error-message {
  color: #ff0000;
  margin-bottom: 15px;
  text-align: center;
}

.close-error {
  display: block;
  margin: 0 auto;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.close-error:hover {
  background-color: #0056b3;
}
